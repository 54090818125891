<template>
  <v-container
    id="alerts"
    fluid
    tag="section"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-dialog
        v-model="carga"
        persistent
        width="350"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="text-h4 font-weight-light white--text">
            {{ textoMensaje }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-navigation-drawer
        absolute
        width="100%"
        permanent
      >
        <div
          class="top-main d-flex flex-column align-center justify-center"
        >
          <v-img
            src="assets/logo_la_n.png"
            max-width="250"
            @click="$router.push(
              { path: '/data' },
              () => {
                $emit('reload')
              },
            )"
          />
        </div>
        <div>
          <v-dialog
            v-model="dialogConsulta"
            width="100%"
            persistent
            transition="dialog-bottom-transition"
          >
            <base-material-card
              icon="mdi-seal"
              color="primary"
              :title="tituloDialog"
            >
              <br>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="filtroSeleccionado"
                    :items="itemsFiltro"
                    hide-selected
                    autofocus
                    item-text="nombre"
                    item-value="cod"
                    clearable
                    label="Busque el tipo de filtro"
                    placeholder="Escriba o seleccione el filtro"
                    @input="getFiltroSeleccionado()"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    tile
                    block
                    color="primary"
                    @click="leerData()"
                  >
                    <v-icon>
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    tile
                    block
                    color="green"
                    @click="generarExcel()"
                  >
                    <v-icon>
                      mdi-file-excel
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-spacer />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar..."
                    hide-details
                    class="ma-0"
                    style="max-width: 750px;"
                  />
                </v-col>
              </v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="dataConsulta"
                    :search="search"
                    light
                    dense
                    class="elevation-1"
                    fixed-header
                    :loading="carga"
                    loading-text="Cargando, por favor espere"
                    :footer-props="{
                      'items-per-page-options': [10, 50, -1],
                      'items-per-page-text': 'Mostrando:',
                      'items-per-page-all-text': 'Todos'
                    }"
                    :header-props="{
                      'sortByText': 'Ordenar por'
                    }"
                    no-data-text="No hay datos registrados"
                    no-results-text="No se encontraron resultados"
                  >
                    <template #[`item.estado_boletin`]="{item}">
                      <v-chip
                        class="ma-2"
                        :color="item.estado_boletin === 'VALIDADO' ? 'green' : item.estado_boletin === 'OBSERVADO' ? 'orange' :'blue'"
                        dark
                      >
                        <v-avatar left>
                          <v-icon> {{ item.estado_boletin === 'VALIDADO' ? 'mdi-check-circle' : item.estado_boletin === 'OBSERVADO' ? 'mdi-alert-box' :'mdi-refresh' }}</v-icon>
                        </v-avatar>
                        {{ item.estado_boletin }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-col>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  block
                  @click="cerrarDialog()"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </base-material-card>
          </v-dialog>
        </div>
        <v-row
          v-if="!carga"
          justify="center"
          no-gutters
        >
          <base-material-card
            color="primary"
            width="550"
            max-width="85%"
            icon="mdi-file-document"
            title="REPORTE DE BOLETINES"
          >
            <div class="mb-n4 mt-2 text-caption font-weight-light">
              Consulte sobre boletines ya sean pendientes, inscritos, observados, corregidos, remitidos o eliminados.
              <br>Para realizar la búsqueda puede seleccionar el estado a buscar, juzgado, secretario o por número de boletín.
            </div>
            <v-card
              class="d-flex flex-column red--text align-center justify-center"
            >
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="estadoSeleccionado"
                  :items="itemsEstado"
                  hide-no-data
                  hide-selected
                  item-text="nombre"
                  item-value="cod"
                  label="Estado"
                  placeholder="Escriba o seleccione el estado de los boletines a buscar"
                  prepend-icon="mdi-checkbox-marked-circle"
                  chips
                  small-chips
                />
              </v-col>
              <v-row>
                <v-col
                  cols="6"
                  md="6"
                >
                  <v-autocomplete
                    v-model="juzgadoSeleccionado"
                    :items="itemsJuzgado"
                    hide-no-data
                    hide-selected
                    item-text="x_nom_instancia"
                    item-value="c_instancia"
                    label="Órgano jurisdiccional"
                    placeholder="Escriba o seleccione el tipo órgano jurisdiccional"
                    prepend-icon="mdi-gavel"
                    chips
                    small-chips
                  />
                </v-col>
                <v-col
                  cols="6"
                  md="6"
                >
                  <v-autocomplete
                    v-model="secretarioSeleccionado"
                    :items="itemsSecretario"
                    hide-no-data
                    hide-selected
                    item-text="x_nom_usuario"
                    item-value="c_usuario"
                    label="Secretarios"
                    placeholder="Escriba o seleccione el secretario jurisdiccional"
                    prepend-icon="mdi-account-search"
                    chips
                    small-chips
                  />
                </v-col>
              </v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="nroBoletin"
                  hide-no-data
                  hide-selected
                  label="Número de boletín"
                  placeholder="Escriba el número de boletín"
                  prepend-icon="mdi-file-search"
                  chips
                  small-chips
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  color="primary"
                  block
                  @click="consultarData()"
                >
                  consultar
                </v-btn>
              </v-col>
            </v-card>
          </base-material-card>
        </v-row>
        <div
          class="text-body-2 white--text text-center"
          @click="$router.push('/cerrar_sesion')"
        >
          {{ new Date().getFullYear() }} &copy; CSJLA
        </div>
      </v-navigation-drawer>
    </v-form>
  </v-container>
</template>

<script>
  import axios from 'axios'
  const lodash = require('lodash')
  export default {
    name: 'Menu',
    data: () => ({
      switchSecretario: false,
      carga: true,
      model: null,
      search: '',
      items: [],
      itemsJuzgado: [],
      itemsSecretario: [],
      juzgadoSeleccionado: '',
      nroBoletin: '',
      secretarioSeleccionado: '',
      estadoSeleccionado: '',
      regla: [(v) => !!v || 'Campo requerido'],
      fechasConsulta: [],
      fechaInicio: '',
      fechaFin: '',
      modalFechaInicio: false,
      modalFechaFin: false,
      dialogConsulta: false,
      dataConsulta: [],
      expanded: [],
      listaSecretarios: [],
      dataAgrupada: [],
      dataInicial: [],
      textoMensaje: 'CARGANDO PÁGINA',
      headers: [],
      itemsEstado: [{ nombre: 'Pendiente', cod: 1 }, { nombre: 'Inscrito', cod: 2 }, { nombre: 'Observado', cod: 3 }, { nombre: 'Corregido', cod: 4 }, { nombre: 'Validado', cod: 5 }, { nombre: 'Pendiente/eliminado', cod: 6 }],
      itemsFiltro: [{ nombre: 'Secretario', cod: 1 }, { nombre: 'Juzgado', cod: 2 }],
      filtroSeleccionado: '',
      headersIni: [
        {
          text: 'Estado bol.',
          value: 'estado_boletin',
        },
        {
          text: 'Nro. bol.',
          value: 'n_boletin',
        },
        {
          text: 'Expediente',
          value: 'x_formato_exp',
          width: 260,
        },
        {
          text: 'Juzgado de la inscripción',
          value: 'x_nom_instancia',
          width: 350,
        },
        {
          text: 'Secretario de la inscripción',
          value: 'x_nom_usuario',
          width: 350,
        },
        {
          text: 'Juzgado actual',
          value: 'x_nom_instancia_actual',
          width: 350,
        },
        {
          text: 'Secretario actual',
          value: 'x_nom_usuario_actual',
          width: 350,
        },
        {
          text: 'Fecha de bol.',
          value: 'f_boletin',
        },
        {
          text: 'Tipo bol.',
          value: 'x_tipo_boletin',
        },
      ],
      tipoData: 1,
      tituloDialog: '',
      searchJuzgado: false,
    }),
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (!this.dataUsuario) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.$store.commit('SET_VALOR', true)
      setTimeout(() => { this.carga = false }, 1500)
      this.leerJuzgados()
      this.leerSecretarios()
      this.headers = this.headersIni
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      getFiltroSeleccionado () {
        this.tipoData = 2
        if (this.filtroSeleccionado === 1) {
          this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `${item.x_nom_usuario}`), 'usuario')
        } else if (this.filtroSeleccionado === 2) {
          this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `${item.x_nom_instancia}`), 'juzgado')
        }
      },
      limpiarCampos () {
        this.juzgadoSeleccionado = ''
        this.secretarioSeleccionado = ''
        this.filtroSeleccionado = ''
        this.search = ''
      },
      cerrarDialog () {
        this.dialogConsulta = false
        this.dataConsulta = []
        this.headers = this.headersIni
        this.limpiarCampos()
      },
      leerData () {
        if (this.estadoSeleccionado === 3) {
          this.headers = [
            {
              text: 'Estado bol.',
              value: 'estado_boletin',
            },
            {
              text: 'Nro. bol.',
              value: 'n_boletin',
            },
            {
              text: 'Expediente',
              value: 'x_formato_exp',
              width: 260,
            },
            {
              text: 'Juzgado de la inscripción',
              value: 'x_nom_instancia',
              width: 350,
            },
            {
              text: 'Secretario de la inscripción',
              value: 'x_nom_usuario',
              width: 350,
            },
            {
              text: 'Juzgado actual',
              value: 'x_nom_instancia_actual',
              width: 350,
            },
            {
              text: 'Secretario actual',
              value: 'x_nom_usuario_actual',
              width: 350,
            },
            {
              text: 'Fecha de bol.',
              value: 'f_boletin',
            },
            {
              text: 'Tipo bol.',
              value: 'x_tipo_boletin',
            }, {
              text: 'Observación',
              value: 'x_observacion',
              width: 350,
            }]
        } else {
          this.headers = this.headersIni
        }
        this.carga = false
        this.dataConsulta = this.dataInicial
        this.dialogConsulta = true
        this.tipoData = 1
        this.filtroSeleccionado = ''
      },
      generarExcel () {
        if (this.tipoData === 1) {
          this.itemsXls = this.dataConsulta.map(obj => {
            const rObj = {}
            rObj['Estado de boletin'] = obj.estado_boletin
            rObj['Nro. de boletin'] = obj.n_boletin
            rObj.Expediente = obj.x_formato_exp
            rObj['Juzgado de la inscripción'] = obj.x_nom_instancia
            rObj['Secretario de la inscripción'] = obj.x_nom_usuario
            rObj['Juzgado actual.'] = obj.x_nom_instancia_actual
            rObj['Secretario actual'] = obj.x_nom_usuario_actual
            rObj['Fecha de boletin'] = obj.f_boletin
            rObj['Tipo de boletin'] = obj.x_tipo_boletin
            rObj['Tipo de observación'] = obj.x_observacion
            rObj.Imputado = obj.nombre
            rObj['DNI Imputado'] = obj.x_doc_id
            return rObj
          })
        } else {
          this.itemsXls = this.dataConsulta.map(obj => {
            const rObj = {}
            rObj.Nombre = obj.nombre
            rObj.Cantidad = obj.cantidad
            return rObj
          })
        }
        this.exportarExcel()
      },
      exportarExcel () {
        const XLSX = require('xlsx')
        const data = XLSX.utils.json_to_sheet(this.itemsXls)
        const workbook = XLSX.utils.book_new()
        const filename = 'reporteBoletines'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
        this.tipoData = 1
      },
      leerJuzgados () {
        const data = {
          c_org_jurisd: '03',
          c_especialidad: 'PE',
        }
        axios
          .post(
            `${window.__env.dataCfg.urlApiSIJWS}instancias/instanciasPorEspecialidadOrgano`, data,
          )
          .then((response) => {
            if (response.data.status === 'shido') {
              this.carga = false
              this.itemsJuzgado = response.data.data
            } else {
              alert('NO SE ENCONTRÓ LA INFORMACIÓN DE DE LOS JUZGADOS EN LAS FECHAS SELECCIONADAS')
              this.carga = false
            }
          })
          .catch((e) => {
            alert('ERROR EN LA CONSULTA A LA BASE DE DATOS, INTENTE NUEVAMENTE MÁS TARDE')
            this.carga = false
                console.log(e); // eslint-disable-line
          })
      },
      leerSecretarios () {
        axios
          .post(
            `${window.__env.dataCfg.urlApiSIJWS}usuarios/usuariosPorPerfil`, { desper: 'Secretario' },
          )
          .then((response) => {
            if (response.data.status === 'shido') {
              this.carga = false
              this.itemsSecretario = response.data.data
            } else {
              alert('NO SE ENCONTRÓ LA INFORMACIÓN DE DE LOS JUZGADOS EN LAS FECHAS SELECCIONADAS')
              this.carga = false
            }
          })
          .catch((e) => {
            alert('ERROR EN LA CONSULTA A LA BASE DE DATOS, INTENTE NUEVAMENTE MÁS TARDE')
            this.carga = false
                console.log(e); // eslint-disable-line
          })
      },
      consultarData () {
        this.textoMensaje = 'CARGANDO INFORMACIÓN DEL SISTEMA'
        this.carga = true
        this.headers = this.headersIni
        if (this.estadoSeleccionado === 3) {
          this.headers = [
            {
              text: 'Estado bol.',
              value: 'estado_boletin',
            },
            {
              text: 'Nro. bol.',
              value: 'n_boletin',
            },
            {
              text: 'Expediente',
              value: 'x_formato_exp',
              width: 260,
            },
            {
              text: 'Juzgado de la inscripción',
              value: 'x_nom_instancia',
              width: 350,
            },
            {
              text: 'Secretario de la inscripción',
              value: 'x_nom_usuario',
              width: 350,
            },
            {
              text: 'Juzgado actual',
              value: 'x_nom_instancia_actual',
              width: 350,
            },
            {
              text: 'Secretario actual',
              value: 'x_nom_usuario_actual',
              width: 350,
            },
            {
              text: 'Fecha de bol.',
              value: 'f_boletin',
            },
            {
              text: 'Tipo bol.',
              value: 'x_tipo_boletin',
            }, {
              text: 'Observación',
              value: 'x_observacion',
              width: 350,
            }]
        }
        if (this.juzgadoSeleccionado.length !== 0) {
          this.searchJuzgado = true
        } else {
          this.searchJuzgado = false
        }
        const data = {
          l_estado: this.estadoSeleccionado,
          c_instancia: this.juzgadoSeleccionado,
          c_usuario: this.secretarioSeleccionado,
          n_boletin: this.nroBoletin,
        }
        axios
          .post(
            `${window.__env.dataCfg.urlApiSIJWS}expedientes/busquedaExpedienteBoletines`, data,
          )
          .then((response) => {
            if (response.data.status === 'shido') {
              this.tituloDialog = 'Resultado de la búsqueda de boletines'
              this.carga = false
              this.dataConsulta = response.data.data
              this.dataInicial = this.dataConsulta
              this.dialogConsulta = true
              this.juzgadoSeleccionado = ''
              this.secretarioSeleccionado = ''
            } else {
              alert('NO SE ENCONTRÓ INFORMACIÓN DE BOLETINES')
              this.carga = false
            }
          })
          .catch((e) => {
            alert('ERROR EN LA CONSULTA A LA BASE DE DATOS, INTENTE NUEVAMENTE MÁS TARDE')
            this.carga = false
                console.log(e); // eslint-disable-line
          })
      },
      leerTodo () {
        this.dataConsulta = this.dataInicial
      },
      agruparSecretario () {
        this.tipoData = 2
        // var dataSe = lodash.groupBy(this.dataInicial, 'x_nom_usuario')
        // const grouped = lodash.groupBy(this.dataInicial, item => `"${item.x_nom_usuario}+${item.x_proveido}"`)
        this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `${item.x_nom_usuario}`), 'usuario')
      },
      agruparEstado () {
        this.tipoData = 2
      },
      agruparJuzgado () {
        this.tipoData = 3
        // this.generarAgrupacion(lodash.groupBy(this.dataInicial, 'x_desc_acto_procesal'), 'acto')
        this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `${item.x_nom_instancia}`), 'acto')
      },
      generarAgrupacion (arrayGroup, agruparPor) {
        this.dataConsulta = []
        this.headers = [
          {
            text: 'Nombre',
            value: 'nombre',
          },
          {
            text: 'Cantidad',
            value: 'cantidad',
          },
        ]
        const arrayFinal = Object.values(arrayGroup)
        for (var i = 0; i < arrayFinal.length; i++) {
          var sumatoria = 0
          for (var j = 0; j < arrayFinal[i].length; j++) {
            sumatoria = 1 + sumatoria
          }
          if (agruparPor === 'usuario') {
            this.dataConsulta.push({ nombre: arrayFinal[i][0].x_nom_usuario, cantidad: sumatoria })
          } else if (agruparPor === 'juzgado') {
            this.dataConsulta.push({ nombre: arrayFinal[i][0].x_nom_instancia, cantidad: sumatoria })
          }
        }
      },
      cerrarSesion () {
        var logOut = confirm('¿Desea cerrar sesión?')
        if (logOut === true) {
          localStorage.removeItem('usuarioDataCSJLA')
          localStorage.removeItem('accesoDataJud')
          this.$router.push('/')
        }
      },
      groupBy (list, keyGetter) {
        const map = new Map()
        list.forEach((item) => {
          const key = keyGetter(item)
          const collection = map.get(key)
          if (!collection) {
            map.set(key, [item])
          } else {
            collection.push(item)
          }
        })
        return map
      },
      formatDate (template, date) {
        const specs = 'YYYY:MM:DD:HH:mm:ss'.split(':')
        date = new Date(
          date || Date.now() - new Date().getTimezoneOffset() * 6e4,
        )
        return date
          .toISOString()
          .split(/[-:.TZ]/)
          .reduce(
            (template, item, i) =>
            // eslint-disable-line
              template.split(specs[i]).join(item),
            template,
          )
      },
    },
  }
</script>
